import { useState, useEffect } from "react";
import env from "react-dotenv";
import Sale from './Sale';
import { Web3Button } from "@web3modal/react";
import { getAccount, getNetwork, watchAccount, watchNetwork, readContract } from '@wagmi/core'

function HomePage() {

  const [account, setAccount] = useState(getAccount());
  const [network, setNetwork] = useState(getNetwork());

  const unwatchAccount = watchAccount((a) => {
    setAccount(a);
  });
  const unwatchNetwork = watchNetwork((n) => {
    setNetwork(n);
  });

  return (
    <section id="home_page">
      <header className="header text-end">
        { <Web3Button /> }
      </header>
      <main className="main container">
        { network.chain && network.chain.id != env.DEFAULT_CHAIN_ID ?
          <span>You have to switch to Polygon chain</span> :
          <Sale /> }
      </main>
      <footer className="footer">
      </footer>
    </section>
  );
}

export default HomePage;
