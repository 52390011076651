import { useState, useEffect } from "react";
import env from "react-dotenv";
import HomePage from './HomePage';
import { EthereumClient, modalConnectors, walletConnectProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygon, polygonMumbai, localhost } from "wagmi/chains";

const chains = env.DEV > 0 ? [polygonMumbai, localhost] : [polygon];

const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: "48cfa343e3d813cfcfe276a53ccb0599" }),
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  return (
    <section id="app">
      <WagmiConfig client={wagmiClient}>
        <HomePage />
      </WagmiConfig>
      <Web3Modal
        projectId="48cfa343e3d813cfcfe276a53ccb0599"
        ethereumClient={ethereumClient}
      />
    </section>
  );
}

export default App;
